<template>
  <transition name="fade">
    <article
      v-if="ui.showPage"
      class="landing-page"
    >
      <section class="landing-page__section lp-splash">
        <header class="landing-page__header">
          <div class="landing-page__header-wrapper">
            <img
              :style="{ 'opacity': partner.hasIframe ? 0 : 1 }"
              :alt="partner.name"
              :src="require(`@/assets/img/logo/${partner.logo.header}`)"
              class="landing-page__logo landing-page__logo--header"
            >

            <nav class="landing-page__nav">
              <ul class="landing-page__menu">
                <li class="landing-page__menu-item">
                  <a
                    href="#sobre"
                    class="landing-page__menu-link"
                    @click.prevent="scrollToSection('#sobre')"
                  >
                    Conheça
                  </a>
                </li>

                <!-- Para remover a condição de esconder item do menu SmartHelo
                  remova a linha 33:
                  `v-if="!isSmartHelp"`
                -->
                <li
                  v-if="!isSmartHelp"
                  class="landing-page__menu-item"
                >
                  <a
                    href="#vantagens"
                    class="landing-page__menu-link"
                    @click.prevent="scrollToSection('#vantagens')"
                  >
                    Vantagens
                  </a>
                </li>

                <!-- Para remover a condição de esconder item do menu SmartHelo
                  remova a linha 51:
                  `v-if="!isSmartHelp"`
                -->
                <li
                  v-if="!isSmartHelp"
                  class="landing-page__menu-item"
                >
                  <a
                    href="#como-funciona"
                    class="landing-page__menu-link"
                    @click.prevent="scrollToSection('#como-funciona')"
                  >
                    Como funciona
                  </a>
                </li>

                <li class="landing-page__menu-item">
                  <a
                    href="#o-que-precisa"
                    class="landing-page__menu-link"
                    @click.prevent="scrollToSection('#o-que-precisa')"
                  >
                    O que precisa
                  </a>
                </li>
              </ul>

              <ul
                v-if="partner.hasMyAccountAccess"
                class="landing-page__menu landing-page__menu--call-to-action"
              >
                <li class="landing-page__menu-item">
                  <router-link
                    :to="{ name: 'UserRegister' }"
                    class="landing-page__menu-link landing-page__menu-link--register"
                  >
                    Cadastre-se
                  </router-link>
                </li>

                <li class="landing-page__menu-item">
                  <router-link
                    :to="{ name: 'UserLogin' }"
                    class="landing-page__menu-link landing-page__menu-link--login"
                  >
                    Login
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        <div class="landing-page__section-wrapper lp-splash__wrapper">
          <!-- Smarthelp: muda título da landingpage -->
          <!--
            Para remover o título específico de SmartHelp
            remova o código que começa aqui
          -->
          <template v-if="isSmartHelp">
            <h1 class="landing-page__title lp-splash__title">
              Teleorientação por vídeo com médicos
              <br class="sm:hidden">
              de primeira linha.
            </h1>
          </template>

          <template v-if="!isSmartHelp">
            <h1 class="landing-page__title lp-splash__title">
              Consultas por vídeo,<br>
              para todo o Brasil.
            </h1>
          </template>
          <!-- E termina aqui -->

          <!-- E descomente esse código
            <h1 class="landing-page__title lp-splash__title">
              Consultas por vídeo,
              <span class="landing-page__title--green">para todo o Brasil.</span>
            </h1>
          -->

          <p class="landing-page__text lp-splash__text">
            Com a mesma qualidade da consulta presencial, você pode cuidar
            de sua saúde sem sair de casa, contando com excelentes médicos.
          </p>

          <div class="lp-splash__actions">
            <appointment-modality-specialty
              v-model="chosenSpecialty"
              :modalities.sync="partner.modalities"
              :specialties.sync="filteredMedicalSpecialties"
              :disabled.sync="ui.isLoading"
              @choose="startAppointmentReservation"
              @change="(v) => chosenModality = v"
            />
          </div>
        </div>
      </section>

      <section
        id="sobre"
        class="landing-page__section lp-about"
      >
        <div class="landing-page__section-wrapper">
          <div class="landing-page__col">
            <img
              class="lp-about__img rounded-xl"
              src="@/assets/img/landingPage/landingpage_about.jpg"
            >
          </div>

          <div class="landing-page__col">
            <h2 class="landing-page__title lp-about__title">
              Consultas, prescrições,
              exames e atestados,
              <span class="landing-page__title--green">de onde você estiver</span>
            </h2>

            <!--
              Para remover o título específico de SmartHelp
              remova o código que começa aqui
            -->
            <template v-if="isSmartHelp">
              <p class="landing-page__text lp-about__text">
                Teleorientação por vídeo com médicos de primeira
                linha no conforto de sua casa.
              </p>
            </template>

            <template v-if="!isSmartHelp">
              <p class="landing-page__text lp-about__text">
                Além de poder realizar consultas médicas de qualquer lugar e
                dispor dos melhores médicos em diversas especialidades, você
                poderá acessar suas receitas, pedidos de exames e atestados de
                qualquer lugar, com total comodidade.
              </p>
            </template>
            <!-- e termina aqui -->

            <!-- Descomente esse código
            <p class="landing-page__text lp-about__text">
              Além de poder realizar consultas médicas de qualquer lugar e
              dispor dos melhores médicos em diversas especialidades, você
              poderá acessar suas receitas, pedidos de exames e atestados de
              qualquer lugar, com total comodidade.
            </p> -->
          </div>
        </div>
      </section>

      <!--
          Para adicionar a seção vantagens em SmartHelp
          remova a linha 204:
          `v-if="!isSmartHelp"`
      -->
      <section
        v-if="!isSmartHelp"
        id="vantagens"
        class="landing-page__section lp-benefits"
      >
        <div class="landing-page__section-wrapper lp-benefits__wrapper">
          <h2 class="landing-page__title lp-benefits__title">
            Vantagens pra você cuidar do que mais importa, a sua saúde.
          </h2>

          <ul class="lp-benefits__list">
            <li class="lp-benefits__item">
              <img
                class="lp-benefits__icon"
                src="@/assets/img/landingPage/ico-vantagens-1.svg"
              >

              <p class="lp-benefits__text">
                Agendamento fácil e rápido para uma experiência simplificada
              </p>
            </li>

            <li class="lp-benefits__item">
              <img
                class="lp-benefits__icon"
                src="@/assets/img/landingPage/ico-vantagens-2.svg"
              >

              <p class="lp-benefits__text">
                Consultas por vídeo, em qualquer lugar que você estiver
              </p>
            </li>

            <li class="lp-benefits__item">
              <img
                class="lp-benefits__icon"
                src="@/assets/img/landingPage/ico-vantagens-3.svg"
              >

              <p class="lp-benefits__text">
                Excelentes médicos, atendendo em todas as especialidades
              </p>
            </li>

            <li class="lp-benefits__item">
              <img
                class="lp-benefits__icon"
                src="@/assets/img/landingPage/ico-vantagens-4.svg"
              >

              <p class="lp-benefits__text">
                Receitas, exames e atestados disponibilizados na hora
              </p>
            </li>
          </ul>
        </div>
      </section>

      <!--
          Para adicionar a seção vantagens em SmartHelp
          remova a linha 268:
          `v-if="!isSmartHelp"`
      -->
      <section
        v-if="!isSmartHelp"
        id="como-funciona"
        class="landing-page__section lp-how"
      >
        <div class="landing-page__section-wrapper lp-how__wrapper">
          <h2 class="landing-page__title lp-how__title">
            Veja como é fácil agendar suas consultas
          </h2>

          <ol class="lp-how__steps">
            <li class="lp-how__steps-item">
              <h3 class="lp-how__steps-title">
                Agende a consulta
              </h3>

              <p class="landing-page__text lp-how__steps-text">
                Agendamento é feito diretamente em nosso site. Basta escolher
                a especialidade e selecionar o data e horário.
              </p>
            </li>

            <li class="lp-how__steps-item">
              <h3 class="lp-how__steps-title">
                Faça o pagamento
              </h3>

              <p class="landing-page__text lp-how__steps-text">
                Ao selecionar o horário, você é direcionado para o pagamento.
                Informe os dados do cartão de crédito e confirme o agendamento.
              </p>
            </li>

            <li class="lp-how__steps-item">
              <h3 class="lp-how__steps-title">
                Passe pela consulta
              </h3>

              <p class="landing-page__text lp-how__steps-text">
                No dia e horário marcados, você receberá um e-mail e um SMS com o
                link para acessar o ambiente online da consulta. Então clique no link enviado
                e confirme seu endereço de e-mail.
              </p>
            </li>

            <li class="lp-how__steps-item">
              <h3 class="lp-how__steps-title">
                Obtenha a receita
              </h3>

              <p class="landing-page__text lp-how__steps-text">
                Após a consulta, você recebe por e-mail os documentos, como receitas e pedidos
                de exame. São todos assinados digitalmente e com valor legal em todo Brasil.
                <!-- Caso o seu médico solicite, o retorno é sem custo e você recebe um e-mail para
                fazer o agendamento em até 30 dias. -->
              </p>
            </li>
          </ol>
        </div>
      </section>

      <section
        id="o-que-precisa"
        class="landing-page__section lp-requirements"
      >
        <div class="landing-page__section-wrapper">
          <div class="landing-page__col">
            <img
              class="lp-requirements__img"
              :src="
                require(`@/assets/img/landingPage/requirements/${partner.theme}_requirements.png`)
              "
            >
          </div>

          <div class="landing-page__col">
            <h3 class="landing-page__title lp-requirements__title">
              O que é preciso para uma consulta por vídeo?
            </h3>

            <ul class="lp-requirements__list">
              <li class="lp-requirements__list-item">
                <i class="icon icon-check lp-requirements__list-icon" />

                <p class="lp-requirements__list-text">
                  Computador com webcam ou celular com câmera frontal
                </p>
              </li>

              <li class="lp-requirements__list-item">
                <i class="icon icon-check lp-requirements__list-icon" />

                <p class="lp-requirements__list-text">
                  Acesso à internet
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <the-footer v-if="!partner.hasIframe" />
    </article>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TheFooter from '@/components/layout/Footer.vue';
import AppointmentModalitySpecialty from '@/components/landingPage/AppointmentModalitySpecialty.vue';
import scrollToSection from '@/mixins/scrollToSection';
import medicalSpecialties from '@/mixins/medicalSpecialties';
import setPatient from '@/mixins/setPatient';

export default {
  name: 'HealthPlaceLandingPage',

  mixins: [scrollToSection, medicalSpecialties, setPatient],

  components: {
    TheFooter,
    AppointmentModalitySpecialty,
  },

  data() {
    return {
      navigator,
      chosenSpecialty: null,
      chosenModality: null,
      ui: {
        isLoading: false,
        showPage: false,
      },
    };
  },

  computed: {
    ...mapGetters('partner', ['partner', 'medicalSpecialties']),

    ...mapGetters('scheduling', ['appointment']),

    appointmentSpecialty() {
      return this.appointment.specialty;
    },

    filteredMedicalSpecialties() {
      return this.medicalSpecialties
        .filter((specialty) => specialty.modality === this.appointment.modality);
    },

    isSmartHelp() {
      return /smarthelp/gi.test(this.partner.theme);
    },
  },

  watch: {
    appointmentSpecialty: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.chosenSpecialty = value;
        }
      },
    },

    chosenModality: {
      deep: false,
      immediate: true,
      handler(value) {
        if (value) {
          this.setAppointment({ modality: value });
        }
      },
    },

    'appointment.modality': {
      deep: false,
      immediate: false,
      handler(modality) {
        if (modality && this.chosenSpecialty) {
          const chosenSpecialty = this.medicalSpecialties
            .find((specialty) => {
              const { id } = this.chosenSpecialty;
              return specialty.id === id && specialty.modality === modality;
            });

          this.chosenSpecialty = { ...chosenSpecialty };
        }
      },
    },
  },

  created() {
    if (this.partner.theme === 'drconecta') {
      window.location = 'https://www.drconecta.com.br/';
      return;
    }

    this.ui.showPage = true;
    this.resetScheduling();
  },

  mounted() {
    this.fetchMedicalSpecialties();
    this.navigator = navigator;
  },

  methods: {
    ...mapActions('ui', ['completeProgressTrackerStep']),
    ...mapActions('scheduling', ['setAppointment', 'resetScheduling']),

    startAppointmentReservation() {
      if (this.chosenModality === 'presential') {
        if (this.chosenSpecialty) {
          this.setAppointment({ specialty: this.chosenSpecialty });
        }

        this.$router.push({ name: 'AppointmentScheduling' });
        return;
      }

      if (this.chosenSpecialty) {
        this.setAppointment({ specialty: this.chosenSpecialty });
        this.completeProgressTrackerStep({ step: 1, status: true });
        this.$router.push({ name: 'AppointmentSchedulingDate' });
        return;
      }

      this.$router.push({ name: 'AppointmentScheduling' });
    },
  },
};
</script>
